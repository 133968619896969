/** @format */

import { RootState } from '../../store';

export const selectorIsAuthenticated = (state: RootState): boolean => !!state.auth?.refresh_token;

export const selectorIsActived = (state: RootState): boolean => {
    if (state?.auth?.expired_at) {
        return state.auth.expired_at > Date.now();
    }
    return false;
};

export const selectorAccessToken = (state: RootState): string | null => {
    return state?.auth?.access_token || null;
};

export const selectorRefreshToken = (state: RootState): string | null => {
    return state?.auth?.refresh_token || null;
};
