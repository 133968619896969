/** @format */
import { FC, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Button, Form, Input, Layout, Spin } from 'antd';
import styled from 'styled-components';
import LogoWGS from '../../../assets/img/logo_wgs.png';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { authLogin } from '../../../app/reducers/auth';
import { selectorIsAuthenticated } from '../../../app/reducers/auth/selectors';
import { Navigate } from 'react-router-dom';

export const Login: FC = () => {
    const dispatch = useAppDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const HandleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUsername(event.target.value);
    };

    const HandleChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    };

    const onSubmit = async (): Promise<void> => {
        setLoading(true);
        try {
            await dispatch(authLogin({ username, password })).unwrap();
        } catch (rejectedValueOrSerializedError) {
            const errorObject: { message: string } = JSON.parse(JSON.stringify(rejectedValueOrSerializedError));
            const errorMessage: { error: string; error_description: string; message: string } = JSON.parse(
                errorObject.message,
            );
            setError(errorMessage.message);
        }
        setLoading(false);
    };

    const onSubmitFailed = (): void => {
        console.log('Failed:');
    };

    const isAuthenticated = useAppSelector(selectorIsAuthenticated);

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Layout>
            <Content>
                <FlexContainer>
                    <HalfLeft>
                        <BackgroundPresentation>
                            <ContentPresentation>
                                <TitlePresentation>
                                    Piattaforma di
                                    <br />
                                    gestione notifiche
                                    <br />
                                    <span style={{ color: '#0173E6', fontWeight: 900 }}>PagoPA e App IO</span>
                                </TitlePresentation>
                                <SubtitlePresentation>
                                    Un unico punto di accesso per la tua organizzazione per comunicare, in modo semplice
                                    e sicuro, avvisi e pagamenti al cittadino.
                                </SubtitlePresentation>
                            </ContentPresentation>
                        </BackgroundPresentation>
                    </HalfLeft>
                    <HalfRight>
                        <BackgroundFormLogin>
                            <ContentFormLogin>
                                <LogoFormLogin src={LogoWGS}></LogoFormLogin>
                                <TitleFormLogin>Benvenuto!</TitleFormLogin>
                                <SubtitleFormLogin>Inserisci le tue credenziali per accedere</SubtitleFormLogin>
                                <Form
                                    name="login"
                                    initialValues={{ remember: true }}
                                    onFinish={onSubmit}
                                    onFinishFailed={onSubmitFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[{ required: true, message: 'Inserisci il tuo username' }]}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Input
                                            size={'large'}
                                            placeholder={'Username'}
                                            prefix={<UserOutlined />}
                                            onChange={HandleChangeUsername}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Inserisci la tua password' }]}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Input.Password
                                            size={'large'}
                                            placeholder={'Password'}
                                            prefix={<LockOutlined />}
                                            visibilityToggle={true}
                                            onChange={HandleChangePassword}
                                        />
                                    </Form.Item>

                                    <Form.Item wrapperCol={{ span: 24 }}>
                                        <Spin indicator={<LoadingOutlined spin={true} />} spinning={loading}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                style={{ width: '100%' }}
                                                size={'large'}
                                            >
                                                Login
                                            </Button>
                                        </Spin>
                                    </Form.Item>
                                </Form>
                                {error && <ErrorLoginMessage>{error}</ErrorLoginMessage>}
                            </ContentFormLogin>
                        </BackgroundFormLogin>
                    </HalfRight>
                </FlexContainer>
            </Content>
        </Layout>
    );
};

const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #f1f2f6;
`;

const HalfLeft = styled.div`
    box-sizing: border-box;
    height: 100%;
    flex-basis: 50%;
`;

const HalfRight = styled.div`
    box-sizing: border-box;
    height: 100%;
    flex-basis: 50%;
`;

const BackgroundPresentation = styled.div`
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(80.96% 80.96% at 42.5% 80.96%, #8dbdee 0%, #2171ea 100%);
`;

const ContentPresentation = styled.div`
    width: 70%;
    height: 60%;
    margin: auto;
    background: rgba(255, 255, 255, 0.23);
    border: 0.5px solid #ffffff;
`;

const TitlePresentation = styled.div`
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-left: 30px;
    margin-top: 70px;

    font-style: normal;
    font-weight: 500;

    font-size: 60px;
    line-height: 72px;

    @media screen and (max-width: 1440px) {
        font-size: 50px;
        line-height: 60px;
    }

    @media screen and (max-width: 1024px) {
        font-size: 38px;
        line-height: 48px;
    }

    align-items: center;

    color: rgba(255, 255, 255, 0.85);
`;

const SubtitlePresentation = styled.div`
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    width: 50%;

    margin-left: 30px;
    margin-top: 30px;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    @media screen and (max-height: 780px) {
        width: 90%;
    }

    align-items: center;

    display: flex;

    color: #ffffff;
`;

const BackgroundFormLogin = styled.div`
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f2f6;
`;

const ContentFormLogin = styled.div`
    width: 400px;
    height: 60%;
    margin: auto;
`;

const LogoFormLogin = styled.img`
    width: 100%;
`;

const TitleFormLogin = styled.div`
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-top: 25px;
    font-size: 30px;
    font-weight: 900;
`;

const SubtitleFormLogin = styled.div`
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin-top: 5px;
    margin-bottom: 25px;
    font-size: 16px;
    display: flex;
    color: rgba(0, 0, 0, 0.7);
`;

const ErrorLoginMessage = styled.p`
    width: 100%;
    text-align: center;
    color: #ff4d4f;
    margin-top: 0;
`;
