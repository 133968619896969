/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { loadAuthFromLocalStorage } from '../../../helpers/loadAuthFromLocalStorage';
import { authLogin } from './actions/login';
import { authRefresh } from './actions/refresh';
import { TAuthLogin } from './definition';

const authReducer = createSlice({
    name: 'auth',
    initialState: loadAuthFromLocalStorage(),
    reducers: {
        logout: state => {
            fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${state?.access_token || ''}`,
                },
            }).then(() => {
                localStorage.removeItem('AUTH_PAPPIOA');
                location.href = '/';
            });
        },
    },
    extraReducers: builder => {
        builder.addCase(authLogin.fulfilled, (state, action) => {
            const newState: TAuthLogin = {
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token,
                expired_at: Date.now() + action.payload.expires_in * 1000,
            };
            localStorage.setItem('AUTH_PAPPIOA', JSON.stringify(newState));
            return newState;
        });
        builder.addCase(authLogin.rejected, () => {
            console.log('login rejected');
        });
        builder.addCase(authRefresh.fulfilled, (state, action) => {
            const newState: TAuthLogin = {
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token,
                expired_at: Date.now() + action.payload.expires_in * 1000,
            };
            localStorage.setItem('AUTH_PAPPIOA', JSON.stringify(newState));
            return newState;
        });
        builder.addCase(authRefresh.rejected, () => {
            console.log('refresh rejected');
            localStorage.removeItem('AUTH_PAPPIOA');
            location.href = '/';
        });
    },
});

export const auth = authReducer.actions;
export { authLogin, authRefresh };
export default authReducer.reducer;
