/** @format */

import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

export const NotFound: FC = () => {
    const { Title } = Typography;

    return (
        <Row>
            <Col xs={24} style={{ textAlign: 'center', marginTop: '30px' }}>
                <Title level={1}>
                    <span style={{ fontWeight: '900' }}>404</span>
                </Title>
                <Title level={2}>
                    <span style={{ fontWeight: '900' }}>Pagina non trovata!</span>
                </Title>
                <Title level={5}>
                    Torna alla <Link to={'/'}>Dashboard</Link>
                </Title>
            </Col>
        </Row>
    );
};
