/** @format */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAuthLoginResponse } from '../definition';
import { RootState } from '../../../store';

export const authRefresh = createAsyncThunk<TAuthLoginResponse, void, { state: RootState }>(
    'auth/refresh',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();

        const request = `${process.env.REACT_APP_API_URL}/token`;

        const bodyData = new URLSearchParams();
        bodyData.append('grant_type', 'refresh_token');
        bodyData.append('refresh_token', state?.auth?.refresh_token || '');
        bodyData.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
        bodyData.append('client_secret', process.env.REACT_APP_CLIENT_SECRET || '');
        bodyData.append('scope', '');

        const response = await fetch(request, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: bodyData,
        });

        const authResponse = await response.json();

        if (!authResponse) {
            throw new Error('Errore durante il refresh');
        }

        if (authResponse.error) {
            localStorage.removeItem('AUTH_PAPPIOA');
            location.href = '/';
        }

        return {
            ...authResponse,
        };
    },
);
