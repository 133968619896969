/** @format */

export const formatDateTime = (datetime: string): string => {
    return new Date(datetime).toLocaleString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};
