/** @format */

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { RootState } from '../app/store';

export const prepareHeaders: (
    headers: Headers,
    api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) => MaybePromise<Headers> = (headers, api) => {
    const rootState = api.getState() as RootState;

    headers.set('Accept', 'application/json');
    if (rootState.auth?.access_token) {
        headers.set('Authorization', `Bearer ${rootState.auth.access_token}`);
    }

    return headers;
};
