/** @format */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { prepareHeaders } from '../../../helpers/prepareHeaders';
import { TNotifica, TNotificheResponse } from './definition';

export const notificheApi = createApi({
    reducerPath: 'notificheApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['notifiche'],
    endpoints: builder => ({
        saveNotifica: builder.mutation<{ data: TNotifica }, object>({
            query: body => ({
                url: `messages`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['notifiche'],
        }),
        getNotifiche: builder.query<TNotificheResponse, { page: number; from?: string; to?: string }>({
            query: ({ page, from, to }) => {
                const queryString = from && to ? `?page=${page}&from=${from}&to=${to}` : '';
                return {
                    url: `messages${queryString}`,
                };
            },
            providesTags: ['notifiche'],
        }),
        getDettaglioNotifica: builder.query<TNotifica, { id: string }>({
            query: ({ id }) => {
                return {
                    url: `messages/${id}`,
                };
            },
            transformResponse: (response: { data: TNotifica }): TNotifica => {
                return response.data;
            },
            providesTags: ['notifiche'],
        }),
    }),
});

export const { useSaveNotificaMutation, useGetNotificheQuery, useGetDettaglioNotificaQuery } = notificheApi;
