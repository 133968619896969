/** @format */
import { FC } from 'react';
import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Table } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Link, useParams } from 'react-router-dom';
import { TNotifica } from '../../../app/reducers/notifica/definition';
import {
    /*    CheckCircleFilled,
    CloseCircleFilled,*/
    CheckCircleOutlined,
    CloseCircleOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { useGetDettaglioNotificaQuery } from '../../../app/reducers/notifica';
import { skipToken } from '@reduxjs/toolkit/query';
import { formatDateTime } from '../../../helpers/formatDateTime';

export const NotificaDetails: FC = () => {
    const { notificaId } = useParams();

    const { data, isFetching } = useGetDettaglioNotificaQuery(notificaId ? { id: notificaId } : skipToken, {
        refetchOnMountOrArgChange: true,
    });

    if (isFetching) {
        return <>Recupero informazioni notifica...</>;
    }

    if (!data) {
        return <>Si è verificato un errore nel recuperare le informazioni della notifica</>;
    }

    const columns = [
        {
            title: 'Codice Destinazione',
            dataIndex: 'fiscalCode',
            key: 'fiscalCode',
            render: (_: string, record: TNotifica): JSX.Element => {
                return (
                    <>
                        {record.fiscalCode}
                        {record.fiscalCodeEnabled ? (
                            <CheckCircleOutlined style={{ color: '#368D34', fontSize: '16px', marginLeft: '6px' }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: '#FF3A3A', marginLeft: '6px' }} />
                        )}
                    </>
                );
            },
        },
        {
            title: 'Esito',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Inviata il',
            dataIndex: 'sentAt',
            key: 'sentAt',
            render: (_: string, record: TNotifica): JSX.Element => {
                return record.sentAt ? (
                    <>{formatDateTime(record.sentAt)}</>
                ) : (
                    <span style={{ color: '#1677ff' }}>
                        <LoadingOutlined spin={true} /> In coda
                    </span>
                );
            },
        },
        {
            title: 'Notifica Push',
            dataIndex: 'notificationPush',
            key: 'notificationPush',
            /*render: (_: string, record: TNotifica): JSX.Element => {
                return record.notificationPush ? (
                    <CheckCircleFilled style={{ color: '#368D34', fontSize: '16px' }} />
                ) : (
                    <CloseCircleFilled style={{ color: '#FF3A3A', fontSize: '16px' }} />
                );
            },*/
            render: (): JSX.Element => {
                return <>n/s</>;
            },
        },
        {
            title: 'Notifica Email',
            dataIndex: 'email',
            key: 'email',
            /*render: (_: string, record: TNotifica): JSX.Element => {
                return record.email ? (
                    <CheckCircleFilled style={{ color: '#368D34', fontSize: '16px' }} />
                ) : (
                    <CloseCircleFilled style={{ color: '#FF3A3A', fontSize: '16px' }} />
                );
            },*/
            render: (): JSX.Element => {
                return <>n/s</>;
            },
        },
        {
            title: 'ID Richiesta',
            dataIndex: 'appioId',
            key: 'appioId',
        },
    ];

    return (
        <>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={24}>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to="/">Notifiche</Link>,
                            },
                            {
                                title: `${notificaId}`,
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Row style={{ marginBottom: '15px' }}>
                <Col xs={24}>
                    <p>
                        Notifica del <strong>{formatDateTime(data.createdAt)}</strong> creata da{' '}
                        <strong>{data.user.name}</strong>
                    </p>
                </Col>
            </Row>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={24}>
                    <Divider orientation={'left'} style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        CONTENUTO DELLA NOTIFICA
                    </Divider>
                </Col>
            </Row>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={24}>
                    <Form name="newNotifica" layout="vertical" initialValues={{ remember: false }} autoComplete="off">
                        <Form.Item
                            name="titolo"
                            label="Titolo"
                            rules={[{ required: true, message: 'Inserisci il titolo della notifica' }]}
                            wrapperCol={{ span: 12 }}
                            initialValue={data.title}
                        >
                            <Input placeholder={'Titolo'} readOnly />
                        </Form.Item>

                        <Form.Item
                            name="oggetto"
                            label="Oggetto"
                            rules={[{ required: true, message: `Inserisci l'oggetto della notifica` }]}
                            wrapperCol={{ span: 24 }}
                            initialValue={data.body}
                        >
                            <TextArea placeholder={'Oggetto'} autoSize={{ minRows: 3 }} readOnly></TextArea>
                        </Form.Item>

                        <Form.Item
                            name="codiceDestinatario"
                            label="Codice Destinazione"
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserisci il codice fiscale del destinatario della notifica',
                                },
                            ]}
                            wrapperCol={{ span: 6 }}
                            initialValue={data.fiscalCode}
                        >
                            <Input placeholder={'Codice Fiscale del destinatario'} readOnly />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={24}>
                    <Divider orientation={'left'} style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        TRASMISSIONE DELLA NOTIFICA
                    </Divider>
                </Col>
            </Row>
            <Table columns={columns} dataSource={[data]} rowKey="id" pagination={false} />
            <Row style={{ paddingTop: '50px' }}>
                <Col xs={24} style={{ textAlign: 'right' }}>
                    <Link to="/">
                        <Button style={{ width: '120px' }}>Indietro</Button>
                    </Link>
                </Col>
            </Row>
        </>
    );
};
