/** @format */

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthenticatedLayout } from './components/layouts/AuthenticatedLayout';
import { Login } from './components/views/Login';
import { NotificaNew } from './components/views/Notifica/new';
import { NotificaDetails } from './components/views/Notifica/details';
import { NotificaIndex } from './components/views/Notifica';
import { NotFound } from './components/views/NotFound';
import { InfoIndex } from './components/views/Info';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { selectorIsActived, selectorIsAuthenticated } from './app/reducers/auth/selectors';
import { authRefresh } from './app/reducers/auth';
import { NotificationProvider } from './components/contexts/NotificationContext';

function App(): JSX.Element {
    const dispatch = useAppDispatch();

    const isAuthenticated = useAppSelector(selectorIsAuthenticated);
    const isActived = useAppSelector(selectorIsActived);

    if (isAuthenticated && !isActived) {
        dispatch(authRefresh());
    }

    return (
        <BrowserRouter>
            <NotificationProvider>
                <Routes>
                    {isAuthenticated && isActived && (
                        <Route path="/" element={<AuthenticatedLayout />}>
                            <Route path="notifiche">
                                <Route path="nuovo" element={<NotificaNew />} />
                                <Route path=":notificaId" element={<NotificaDetails />} />
                            </Route>
                            <Route path="info" element={<InfoIndex />} />
                            <Route index path="" element={<NotificaIndex />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    )}
                    <Route path="*" element={<Login />} />
                </Routes>
            </NotificationProvider>
        </BrowserRouter>
    );
}

export default App;
