/** @format */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAuthLoginResponse, TAuthLoginAction } from '../definition';

export const authLogin = createAsyncThunk<TAuthLoginResponse, TAuthLoginAction>(
    'auth/login',
    async ({ username, password }) => {
        const request = `${process.env.REACT_APP_API_URL}/token`;

        const bodyData = new URLSearchParams();
        bodyData.append('grant_type', 'password');
        bodyData.append('username', username);
        bodyData.append('password', password);
        bodyData.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
        bodyData.append('client_secret', process.env.REACT_APP_CLIENT_SECRET || '');
        bodyData.append('scope', '');

        const response = await fetch(request, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: bodyData,
        });

        if (!response.ok) {
            throw await response.text();
        }

        const authResponse = await response.json();

        if (!authResponse) {
            throw new Error("Errore durante l'autenticazione");
        }

        return {
            ...authResponse,
        };
    },
);
