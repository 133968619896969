/** @format */

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './reducers/auth';
import { setupListeners } from '@reduxjs/toolkit/query';
import { notificheApi } from './reducers/notifica';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        notificheApi: notificheApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(notificheApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
