/** @format */
import { ChangeEvent, FC, useState } from 'react';
import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { Link, useNavigate } from 'react-router-dom';
import { useSaveNotificaMutation } from '../../../app/reducers/notifica';
import { useNotification } from '../../contexts/NotificationContext';

export const NotificaNew: FC = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [readOnlyForm, setReadOnlyForm] = useState(false);

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [fiscalCode, setFiscalCode] = useState('');

    const { setMessage } = useNotification();

    const [saveNotifica] = useSaveNotificaMutation();

    const HandleChangeTitolo = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTitle(event.target.value);
    };

    const HandleChangeOggetto = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setBody(event.target.value);
    };

    const HandleChangeCodiceDestinatario = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFiscalCode(event.target.value);
    };

    const onSubmit = async (): Promise<void> => {
        setLoading(true);
        const responseSaveNotifica = await saveNotifica({ title, body, fiscalCode });
        setLoading(false);
        if ('data' in responseSaveNotifica) {
            setMessage({
                title: 'Notifica creata',
                body: 'Notifica aggiunta correttamente alla coda di invio',
                type: 'success',
                placement: 'topRight',
            });
            setReadOnlyForm(true);
            navigate(`/notifiche/${responseSaveNotifica.data.data.id}`);
        } else {
            setMessage({
                title: 'Errore',
                body: 'Si è verificato un errore durante la registrazione della notifica',
                type: 'error',
                placement: 'topRight',
            });
        }
    };

    const onSubmitFailed = (): void => {
        console.log('Failed!');
    };

    return (
        <>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={24}>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to="/">Notifiche</Link>,
                            },
                            {
                                title: 'Nuova notifica',
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={24}>
                    <Divider orientation={'left'} style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        CONTENUTO DELLA NOTIFICA
                    </Divider>
                </Col>
            </Row>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={24}>
                    <Form
                        name="newNotifica"
                        layout="vertical"
                        initialValues={{ remember: false }}
                        onFinish={onSubmit}
                        onFinishFailed={onSubmitFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="titolo"
                            label="Titolo (10-120 caratteri)"
                            wrapperCol={{ span: 12 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserisci il titolo della notifica - Minimo 10 caratteri, massimo 120',
                                    min: 10,
                                    max: 120,
                                },
                            ]}
                        >
                            <Input
                                placeholder={'Titolo'}
                                onChange={HandleChangeTitolo}
                                maxLength={120}
                                readOnly={readOnlyForm}
                            />
                        </Form.Item>

                        <Form.Item
                            name="oggetto"
                            label="Oggetto (80-10.000 caratteri)"
                            rules={[
                                {
                                    required: true,
                                    message: `Inserisci l'oggetto della notifica - Minimo 80 caratteri, massimo 10.000`,
                                    min: 80,
                                    max: 10000,
                                },
                            ]}
                            wrapperCol={{ span: 24 }}
                        >
                            <TextArea
                                placeholder={'Oggetto'}
                                onChange={HandleChangeOggetto}
                                maxLength={10000}
                                readOnly={readOnlyForm}
                                autoSize={{ minRows: 3 }}
                                showCount
                            ></TextArea>
                        </Form.Item>

                        <Form.Item
                            name="codiceDestinatario"
                            label="Codice Destinazione"
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserisci il codice fiscale del destinatario della notifica',
                                    min: 16,
                                    max: 16,
                                    pattern: new RegExp(
                                        '^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$',
                                    ),
                                },
                            ]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Input
                                placeholder={'Codice Fiscale del destinatario'}
                                onChange={HandleChangeCodiceDestinatario}
                                maxLength={16}
                                readOnly={readOnlyForm}
                                onInput={(event: ChangeEvent<HTMLInputElement>): string =>
                                    (event.target.value = event.target.value.toUpperCase())
                                }
                            />
                        </Form.Item>

                        <Row style={{ paddingTop: '50px' }}>
                            <Col xs={24} style={{ textAlign: 'right' }}>
                                <Space wrap>
                                    <Form.Item>
                                        <Link to="/">
                                            <Button htmlType="button" style={{ width: '120px' }}>
                                                Indietro
                                            </Button>
                                        </Link>
                                    </Form.Item>
                                    <Form.Item>
                                        <Spin indicator={<LoadingOutlined spin={true} />} spinning={loading}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                style={{ width: '120px' }}
                                                disabled={readOnlyForm}
                                            >
                                                Invia
                                            </Button>
                                        </Spin>
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
