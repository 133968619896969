/** @format */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import './index.css';
import './custom.scss';
import { ConfigProvider } from 'antd';
import itIT from 'antd/locale/it_IT';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider locale={itIT} theme={{ token: { fontFamily: 'Titillium Web' } }}>
                <App />
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
);
