/** @format */
import { TAuthState } from '../app/reducers/auth/definition';

export const loadAuthFromLocalStorage = (): TAuthState => {
    const localStorageAuth = localStorage.getItem('AUTH_PAPPIOA');

    if (!localStorageAuth) {
        return null;
    }

    return JSON.parse(localStorageAuth);
};
