/** @format */
import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import { IconType, NotificationPlacement } from 'antd/lib/notification/interface';

interface TNotification {
    title: string;
    body: string;
    type: IconType;
    placement: NotificationPlacement;
}

interface NotificationState {
    message: TNotification;
    setMessage(message: TNotification): void;
}

const defaultNotificationState: NotificationState = {
    message: { title: '', body: '', type: 'info', placement: 'top' },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMessage: () => {},
};

const NotificationContext = React.createContext(defaultNotificationState);

export const useNotification = (): NotificationState => useContext(NotificationContext);

const Context = React.createContext({ title: '', body: '', type: 'info', placement: 'top' });

export const NotificationProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [message, setMessage] = useState<TNotification>({ title: '', body: '', type: 'info', placement: 'top' });

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (message.title && message.body)
            api[message.type]({
                message: message.title,
                description: <Context.Consumer>{(): string => message.body}</Context.Consumer>,
                placement: message.placement,
            });
    }, [message]);

    const contextValue = useMemo(() => message, []);

    return (
        <NotificationContext.Provider value={{ message, setMessage }}>
            <Context.Provider value={contextValue}>
                {contextHolder}
                {children}
            </Context.Provider>
        </NotificationContext.Provider>
    );
};
