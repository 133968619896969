/** @format */
import { FC, useState } from 'react';
import { Button, Col, DatePicker, Row, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/it';
import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { TNotifiche } from '../../../app/reducers/notifica/definition';
import { Link } from 'react-router-dom';
import { useGetNotificheQuery } from '../../../app/reducers/notifica';
import { formatDateTime } from '../../../helpers/formatDateTime';

interface RangeValue {
    from: Dayjs;
    to: Dayjs;
}

export const NotificaIndex: FC = () => {
    const { RangePicker } = DatePicker;

    const columns = [
        {
            title: 'Data creazione',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_: string, record: TNotifiche): JSX.Element => {
                return <>{formatDateTime(record.createdAt)}</>;
            },
        },
        {
            title: 'Titolo notifica',
            dataIndex: 'title',
        },
        {
            title: 'Codice Destinazione',
            dataIndex: 'fiscalCode',
        },
        {
            title: 'Creata da',
            dataIndex: ['user', 'name'],
        },
        {
            title: 'Inviata il',
            dataIndex: 'sentAt',
            render: (_: string, record: TNotifiche): JSX.Element => {
                return record.sentAt ? (
                    <>{formatDateTime(record.sentAt)}</>
                ) : (
                    <span style={{ color: '#1677ff' }}>
                        <LoadingOutlined spin={true} /> In coda
                    </span>
                );
            },
        },
        {
            title: '',
            dataIndex: 'azioni',
            render: (_: string, record: TNotifiche): JSX.Element => (
                <Link to={`notifiche/${record.id}`}>
                    <InfoCircleOutlined style={{ fontSize: '18px' }} />
                </Link>
            ),
        },
    ];

    const [dateFromSelected, setDateFromSelected] = useState(dayjs().format('YYYY-MM-DD'));
    const [dateToSelected, setDateEndSelected] = useState(dayjs().format('YYYY-MM-DD'));
    const [pageSelected, setPageSelected] = useState(1);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onRangeChange = (values: RangeValue<Dayjs>): void => {
        setDateFromSelected(dayjs(values[0]['$d']).format('YYYY-MM-DD'));
        setDateEndSelected(dayjs(values[1]['$d']).format('YYYY-MM-DD'));
    };

    const { data, isFetching } = useGetNotificheQuery({
        page: pageSelected,
        from: dateFromSelected,
        to: dateToSelected,
    });

    return (
        <>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={12}>
                    <h1 style={{ marginBottom: 0 }}>Notifiche</h1>
                    <p style={{ marginTop: '8px', color: '#333' }}>
                        Visualizza e gestisci l’elenco delle notifiche trasmesse e in corso di invio
                    </p>
                </Col>
                <Col xs={12} style={{ textAlign: 'end' }}>
                    <Link to="notifiche/nuovo">
                        <Button
                            htmlType={'button'}
                            size={'large'}
                            type={'primary'}
                            style={{
                                marginTop: '20px',
                                width: '160px',
                            }}
                        >
                            <PlusOutlined /> Notifica
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <RangePicker
                        style={{ width: '260px' }}
                        format={'DD-MM-YYYY'}
                        allowClear={false}
                        defaultValue={[dayjs(dateFromSelected), dayjs(dateToSelected)]}
                        onChange={onRangeChange}
                    />
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={data?.data}
                rowKey="id"
                pagination={{
                    total: data?.meta.total,
                    defaultPageSize: 15,
                    showTotal: total => `Totale ${total} elementi`,
                    onChange: (current): void => {
                        setPageSelected(current);
                    },
                }}
                loading={isFetching}
            />
        </>
    );
};
