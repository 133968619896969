/** @format */

import { FC } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Layout, Menu } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { InfoCircleOutlined, ShakeOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { auth } from '../../../app/reducers/auth';
import MenuItem from 'antd/lib/menu/MenuItem';

export const AuthenticatedLayout: FC = () => {
    const dispatch = useAppDispatch();

    const onLogoutClick = (): void => {
        dispatch(auth.logout());
    };

    return (
        <>
            <Layout>
                <CustomMenuLeft theme="light" mode="horizontal" defaultSelectedKeys={['notifiche']}>
                    <MenuItem key="notifiche" icon={<ShakeOutlined />}>
                        <Link to="/">Notifiche</Link>
                    </MenuItem>
                    <MenuItem key="info" icon={<InfoCircleOutlined />}>
                        <Link to="info">Info</Link>
                    </MenuItem>
                </CustomMenuLeft>
                <CustomMenuRight
                    theme="light"
                    mode="horizontal"
                    items={[
                        {
                            label: `Profilo`,
                            key: 'profile',
                            icon: <UserOutlined />,
                            children: [
                                {
                                    label: 'Logout',
                                    key: 'logout',
                                    onClick: onLogoutClick,
                                },
                            ],
                            style: { position: 'absolute', right: 0 },
                        },
                    ]}
                />
                <CustomContent>
                    <Outlet />
                </CustomContent>
                {/*                <CustomFooter>
                    POC Notification Management System PagoPA/AppIO - Developed by Webgenesys Spa
                </CustomFooter>*/}
            </Layout>
        </>
    );
};

const CustomMenuLeft = styled(Menu)`
    width: 100%;
    position: absolute;
    padding-left: 24px;
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

const CustomMenuRight = styled(Menu)`
    width: 350px;
    position: absolute;
    right: 24px;
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

const CustomContent = styled(Content)`
    margin-top: 40px;
    padding: 36px 72px 48px 72px;
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: #f1f2f6 !important;
`;

/*const CustomFooter = styled(Footer)`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 12px 24px 12px 24px;
    font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: white !important;
    font-size: 11px;
`;*/
