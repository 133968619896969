/** @format */
import { FC } from 'react';
import { Col, Row } from 'antd';

export const InfoIndex: FC = () => (
    <>
        <Row style={{ marginBottom: '25px' }}>
            <Col xs={12}>
                <h1 style={{ marginBottom: 0 }}>Info</h1>
            </Col>
        </Row>
        <Row style={{ marginBottom: '20px' }}>
            <Col xs={24}>
                <p>
                    Questo software è la <i>proof of concept</i> di una piattaforma{' '}
                    <strong>
                        Notification Management System per {`l'invio`} di avvisi AppIo e notifiche di pagamento PagoPA
                    </strong>
                    .
                </p>
                <p>
                    Un NMS consente di creare e pianificare notifiche, specificare a chi inviarle e in quale momento,{' '}
                    nonché personalizzarle in base alle preferenze degli utenti. Inoltre consente di monitorare e
                    analizzare la ricezione degli invii, fornendo feedback in tempo reale.
                    <br />
                    Sfruttando i servizi offerti da{' '}
                    <a href="https://www.pagopa.gov.it/" target="_blank" rel="noreferrer">
                        PagoPA
                    </a>
                    , si vuole anche semplificare il processo di gestione e invio di notifiche di pagamento ai
                    cittadini, fornendo loro un metodo semplice per effettuare i pagamenti tramite {`l'`}
                    <a href="https://io.italia.it/" target="_blank" rel="noreferrer">
                        App IO
                    </a>
                </p>
                <p>
                    {`Nello specifico, questa POC permette di testare la creazione, l'invio e il monitoraggio delle
                    notifiche utilizzando come canale di invio l'App IO.`}
                </p>
                <p>
                    Il software è stato progettato e sviluppato da{' '}
                    <a href="https://www.webgenesys.it/" target="_blank" rel="noreferrer">
                        Webgenesys Spa
                    </a>
                </p>
            </Col>
        </Row>
    </>
);
